import SVFooterImageGallery from '@apps/www/src/www/components/SVFooterImageGallery';
import SVInfoSection from '@apps/www/src/www/components/SVInfoSection';
import SVNav from '@apps/www/src/www/components/SVNav';
import SVResourceCardTemplate from '@apps/www/src/www/components/SVResourceCardTemplate';
import SVSocialProfileGrid, { ISocialProof } from '@apps/www/src/www/components/SVSocialProfileGrid';
import SVPageMeta from '@apps/www/src/www/containers/SVPageMeta';
import SVRedirect from '@apps/www/src/www/containers/SVRedirect';
import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import useAuthUser from '@apps/www/src/www/hooks/useAuthUser';
import useHasCourse from '@apps/www/src/www/hooks/useHasCourse';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useLanguageSetting from '@apps/www/src/www/hooks/useLanguageSetting';
import useScrollAnimation from '@apps/www/src/www/hooks/useScrollAnimation';
import useViewportName from '@apps/www/src/www/hooks/useViewportName';
import { toggleEditing } from '@apps/www/src/www/reducers/grid';
import { openGridSettings } from '@apps/www/src/www/reducers/ui';
import SVActions from '@pkgs/shared-client/components/SVActions';
import SVButton, { SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVDropdown from '@pkgs/shared-client/components/SVDropdown';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVFlexSpacer from '@pkgs/shared-client/components/SVFlexSpacer';
import SVImage from '@pkgs/shared-client/components/SVImage';
import SVLandingPageFooter from '@pkgs/shared-client/components/SVLandingPageFooter';
import SVLink from '@pkgs/shared-client/components/SVLink';
import SVMinimalVideoPlayer from '@pkgs/shared-client/components/SVMinimalVideoPlayer';
import SVPageHeader from '@pkgs/shared-client/components/SVPageHeader';
import SVPageMargins from '@pkgs/shared-client/components/SVPageMargins';
import SVPageSubtitle from '@pkgs/shared-client/components/SVPageSubtitle';
import SVPageTitle from '@pkgs/shared-client/components/SVPageTitle';
import config from '@pkgs/shared-client/config';
import SVWithBodyClass from '@pkgs/shared-client/containers/SVWithBodyClass';
import { scrollToElement } from '@pkgs/shared-client/helpers/dom';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import SVCourseCoverPNG from '@pkgs/shared-client/img/course/course-cover-trailer.png';
import SVFigmaCourseTemplatePNG from '@pkgs/shared-client/img/course/course-figma-template.png';
import SVParallaxImage02 from '@pkgs/shared-client/img/course/course-parallax-02.png';
import SVParallaxImage from '@pkgs/shared-client/img/course/course-parallax.png';
import SVProjectsImage02 from '@pkgs/shared-client/img/course/course-projects-left-image.png';
import SVProjectsImage from '@pkgs/shared-client/img/course/course-projects.png';
import SVFigmaCourseContentPNG from '@pkgs/shared-client/img/course/figma-course-content.png';
import iconCheckItems from '@pkgs/shared-client/img/course/icon-check-items.svg';
import ogImage from '@pkgs/shared-client/img/course/og-image.jpg';
import SVAndreSouzaAvatar from '@pkgs/shared-client/img/course/social_proof/andre_souza_avatar.png';
import SVAndreaAlabastroAvatar from '@pkgs/shared-client/img/course/social_proof/andrea_alabastro_avatar.png';
import SVAshleyGauntSeoAvatar from '@pkgs/shared-client/img/course/social_proof/ashley_gaunt_seo_avatar.png';
import SVColinDunnAvatar from '@pkgs/shared-client/img/course/social_proof/colin_dunn_avatar.png';
import SVDiegoMendesAvatar from '@pkgs/shared-client/img/course/social_proof/diego_mendes_avatar.png';
import SVFabioSassoAvatar from '@pkgs/shared-client/img/course/social_proof/fabio_sasso_avatar.png';
import SVGuilhermeDienstmannAvatar from '@pkgs/shared-client/img/course/social_proof/guilherme_dienstmann_avatar.png';
import SVJoshKillAvatar from '@pkgs/shared-client/img/course/social_proof/josh_kill_avatar.png';
import SVMarkLaughlinAvatar from '@pkgs/shared-client/img/course/social_proof/mark_laughlin_avatar.png';
import SVMichaelLashfordAvatar from '@pkgs/shared-client/img/course/social_proof/michael_lashford_avatar.png';
import SVMilyMcClellandAvatar from '@pkgs/shared-client/img/course/social_proof/mily_mcclelland_avatar.png';
import SVCourseAfterTemplateMP4 from '@pkgs/shared-client/videos/course-after-template.mp4';
import SVCourseTrailerEnglish from '@pkgs/shared-client/videos/course-trailer-english.mp4';
import SVCourseTrailer from '@pkgs/shared-client/videos/course-trailer.mp4';
import SVMeetYourHostVideo from '@pkgs/shared-client/videos/meet-your-host.mp4';
import Language, { getLanguageLabel } from '@pkgs/shared/enums/Language';
import ViewportKind from '@pkgs/shared/enums/ViewportKind';
import formatURL from '@pkgs/shared/helpers/formatURL';
import clsx from 'clsx';
import { ChevronDown } from 'lucide-react';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const SAVEE_URL_COURSE_CHECKOUT = '/api/course/checkout/';

const _ScrollToPricingButton = ({ label, className }: { label: string; className?: string }) => {
	const handleClick = useEventCallback(() => {
		scrollToElement(document.getElementById('pricing') as HTMLElement);
	});

	return (
		<SVButton
			use={SVButtonUSES.PRIMARY}
			className={clsx('w-fit px-8 py-6 font-medium', className)}
			onClick={handleClick}
		>
			{label}
		</SVButton>
	);
};

const _GetCourseButton = ({
	label,
	className,
	language,
	usePadding = true,
}: {
	label: string;
	className?: string;
	language: (typeof Language)[keyof typeof Language];
	usePadding?: boolean;
}) => {
	const HOTMART_URL = config.course.hotmartURL;

	return (
		<SVButton
			Component={SVLink}
			use={SVButtonUSES.PRIMARY}
			to={language === Language.PT_BR ? HOTMART_URL : SAVEE_URL_COURSE_CHECKOUT}
			className={clsx('w-fit font-medium', usePadding && 'px-8 py-6', className)}
		>
			{label}
		</SVButton>
	);
};

const _Navbar = () => {
	const [language, setLanguage] = useLanguageSetting();
	const dispatch = useDispatch();
	const authSettings = useAuthSettings(['email', 'unreadNotificationsCount']);
	const handleToggleEditing = useEventCallback(() => dispatch(toggleEditing()));
	const handleOpenGridSettings = useEventCallback(() => dispatch(openGridSettings()));
	const team = useAuthTeam();
	const user = useAuthUser(['name', 'avatarURL', 'url']);
	const router = useRouter();
	const isLoggedIn = useIsLoggedIn();

	return (
		<div className="relative flex items-center h-nav-height">
			<SVPageMargins className="flex items-center flex-grow">
				<SVNav.NavLogo isEditing={false} locationPath={router.asPath} />
				<SVFlexSpacer />
				<div className="flex items-center space-x-3">
					<SVDropdown
						triggerType="click"
						renderTrigger={({ isOpen: _, ...props }) => (
							<SVActions.Item
								onClick={() => {}}
								{...props}
								className="flex items-center justify-center space-x-2"
							>
								<div className="text-base font-semibold text-gray-600">
									{getLanguageLabel(language)}
								</div>
								<ChevronDown className="w-5 h-5 text-gray-600" />
							</SVActions.Item>
						)}
						renderContent={() => (
							<SVDropdownContent.Links>
								{Object.values(Language).map((language, index) => (
									<SVDropdownContent.Links.Item
										key={index}
										title={getLanguageLabel(language)}
										onClick={() => setLanguage(language)}
									>
										{getLanguageLabel(language)}
									</SVDropdownContent.Links.Item>
								))}
							</SVDropdownContent.Links>
						)}
					/>
					{isLoggedIn ? (
						<SVNav.Avatar
							userEmail={authSettings?.email || ''}
							site={null}
							userName={user?.name || ''}
							userAvatarURL={user?.avatarURL || ''}
							userURL={user?.url || ''}
							team={team}
							unreadNotificationsCount={authSettings?.unreadNotificationsCount || 0}
							isDarkMode={true}
							showGridControls={false}
							onToggleEditing={handleToggleEditing}
							userHasExpiredSubscription={false}
							onOpenGridSettings={handleOpenGridSettings}
							userCanSeeBillingPage={true}
						/>
					) : null}
				</div>
			</SVPageMargins>
		</div>
	);
};

const _EpisodesParallax = () => {
	const containerRef = useRef<HTMLDivElement>(null);
	const row1Ref = useRef<HTMLDivElement>(null);
	const row2Ref = useRef<HTMLDivElement>(null);

	useScrollAnimation({
		containerRef,
		update: (progress: number, { windowHeight }) => {
			const movement = Math.min(150, (windowHeight / 900) * 150);

			if (row1Ref.current && row2Ref.current) {
				// 0 to 1 to -1 to 1
				const progressBoth = progress * 2 - 1;

				row1Ref.current.style.transform = `translateX(${progressBoth * -1 * movement}px)`;
				row2Ref.current.style.transform = `translateX(${progressBoth * movement}px)`;
			}
		},
		enableAnimationAlways: true,
	});

	return (
		<div className="w-full pt-12 overflow-hidden" ref={containerRef}>
			<div className="-sm:mb-[2.7%] relative mb-[1.8%]" ref={row1Ref}>
				<div
					className="-sm:pb-[19.89%] relative ml-[-25%] w-[150%] bg-contain bg-center bg-repeat-x pb-[13.26%]"
					style={{ backgroundImage: `url(${SVParallaxImage})` }}
				/>
			</div>
			<div className="relative mb-[1.8%]" ref={row2Ref}>
				<div
					className="-sm:pb-[19.89%] relative ml-[-25%] w-[150%] bg-contain bg-center bg-repeat-x pb-[13.26%]"
					style={{ backgroundImage: `url(${SVParallaxImage02})` }}
				/>
			</div>
		</div>
	);
};

const _Projects = () => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const image1Ref = useRef<HTMLDivElement | null>(null);
	const image2Ref = useRef<HTMLDivElement | null>(null);

	useScrollAnimation({
		containerRef,
		bottomThreshold: 0.8,
		update: (progress, { windowHeight }) => {
			const movement = Math.min(100, (windowHeight / 900) * 100);

			if (image1Ref.current && image2Ref.current) {
				image1Ref.current.style.transform = `translateY(${progress * movement}px)`;
				image2Ref.current.style.transform = `translateY(${progress * movement * -1}px)`;
			}
		},
		enableAnimationAlways: true,
	});

	return (
		<div className="flex w-full -md:mt-16" ref={containerRef}>
			<div className="relative w-full h-full space-x-6 flex-center">
				<div ref={image1Ref}>
					<SVImage
						src={SVProjectsImage02}
						className="-sm:max-w-[45vw] -lg:max-h-[600px] -lg:w-[250px] -mt-4 max-h-[800px] w-[350px]"
						alt="Projects"
					/>
				</div>
				<div ref={image2Ref}>
					<SVImage
						src={SVProjectsImage}
						className="-sm:max-w-[45vw] -lg:max-h-[600px] -lg:w-[250px] -mt-10 max-h-[800px] w-[350px]"
						alt="Projects"
					/>
				</div>
			</div>
		</div>
	);
};

const _ExtraContentList = () => {
	const { t } = useTranslation('course');

	const EXTRA_CONTENT_LIST: {
		title: string;
		description: string;
		source: string;
	}[] = [
		{
			title: t('extraBonusSection.extraBonus01.title'),
			description: t('extraBonusSection.extraBonus01.description'),
			source: SVCourseAfterTemplateMP4,
		},
		{
			title: t('extraBonusSection.extraBonus02.title'),
			description: t('extraBonusSection.extraBonus02.description'),
			source: SVFigmaCourseTemplatePNG,
		},
		{
			title: t('extraBonusSection.extraBonus03.title'),
			description: t('extraBonusSection.extraBonus03.description'),
			source: SVFigmaCourseContentPNG,
		},
	];

	return <SVResourceCardTemplate items={EXTRA_CONTENT_LIST} />;
};

const _Page = () => {
	const { t } = useTranslation('course');
	const hasCourse = useHasCourse();
	const [language] = useLanguageSetting();

	const OLD_PRICE =
		language === Language.EN_US
			? '<span class="line-through">$499</span>'
			: 'De <span class="line-through">R$1.999 </span> por apenas';
	const NEW_PRICE = language === Language.EN_US ? '$249' : 'R$1.199';

	const OFFER_DETAILS_LIST: string[] = [
		t('courseDetailsSection.included.01'),
		t('courseDetailsSection.included.02'),
		t('courseDetailsSection.included.03'),
		t('courseDetailsSection.included.04'),
		t('courseDetailsSection.included.05'),
		t('courseDetailsSection.included.06'),
		t('courseDetailsSection.included.07'),
	];

	const SOCIAL_PROOF_LIST: ISocialProof[] = [
		{
			title: t('quotesSection.quote01.title'),
			userDescription: t('quotesSection.quote01.user_description'),
			userName: 'Andre Souza',
			avatarURL: SVAndreSouzaAvatar,
		},
		{
			title: t('quotesSection.quote05.title'),
			userDescription: t('quotesSection.quote05.user_description'),
			userName: 'Mily McClelland',
			avatarURL: SVMilyMcClellandAvatar,
		},
		{
			title: t('quotesSection.quote09.title'),
			userDescription: t('quotesSection.quote09.user_description'),
			userName: 'Michael Lashford',
			avatarURL: SVMichaelLashfordAvatar,
		},
		{
			title: t('quotesSection.quote02.title'),
			userDescription: t('quotesSection.quote02.user_description'),
			userName: 'Ashley Gaunt-Seo',
			avatarURL: SVAshleyGauntSeoAvatar,
		},
		{
			title: t('quotesSection.quote06.title'),
			userDescription: t('quotesSection.quote06.user_description'),
			userName: 'Mark Laughlin',
			avatarURL: SVMarkLaughlinAvatar,
		},
		{
			title: t('quotesSection.quote10.title'),
			userDescription: t('quotesSection.quote10.user_description'),
			userName: 'Guilherme Dienstmann',
			avatarURL: SVGuilhermeDienstmannAvatar,
		},
		{
			title: t('quotesSection.quote03.title'),
			userDescription: t('quotesSection.quote03.user_description'),
			userName: 'Colin Dunn',
			avatarURL: SVColinDunnAvatar,
		},
		{
			title: t('quotesSection.quote07.title'),
			userDescription: t('quotesSection.quote07.user_description'),
			userName: 'Fabio Sasso',
			avatarURL: SVFabioSassoAvatar,
		},
		{
			title: t('quotesSection.quote11.title'),
			userDescription: t('quotesSection.quote11.user_description'),
			userName: 'Josh Kill',
			avatarURL: SVJoshKillAvatar,
		},
		{
			title: t('quotesSection.quote04.title'),
			userDescription: t('quotesSection.quote04.user_description'),
			userName: 'Andrea Alabastro',
			avatarURL: SVAndreaAlabastroAvatar,
		},
		{
			title: t('quotesSection.quote08.title'),
			userDescription: t('quotesSection.quote08.user_description'),
			userName: 'Diego Mendes',
			avatarURL: SVDiegoMendesAvatar,
		},
	];

	const viewportName = useViewportName();

	const isSmallNav =
		viewportName === 'small' ||
		(!viewportName && ViewportKind.MOBILE) ||
		viewportName === 'medium' ||
		viewportName === 'medium-portrait';

	if (hasCourse) {
		return <SVRedirect to="/course/watch/" />;
	}

	const meta = {
		title: t('title'),
		description: t('description'),
		image: {
			url: formatURL(config.staticURL, ogImage),
			width: 1200,
			height: 630,
		},
	};

	return (
		<>
			<SVPageMeta {...meta} />
			<_Navbar />
			<div className="flex flex-col w-full max-w-full overflow-hidden">
				<SVPageHeader className="my-24 -sm:px-6 -sm:my-12">
					<SVPageTitle className="mb-6 font-medium text-gray-300 type-huge">
						{t('title')}
					</SVPageTitle>
					<SVPageSubtitle>{t('description')}</SVPageSubtitle>
				</SVPageHeader>

				<div className="-sm:mb-24 relative mx-auto mb-40 h-full w-full max-w-screen-2xl overflow-hidden rounded-xl px-[var(--page-margin)] md:aspect-video">
					<SVMinimalVideoPlayer
						src={language === Language.EN_US ? SVCourseTrailerEnglish : SVCourseTrailer}
						className="object-cover w-full h-full rounded-xl"
						poster={SVCourseCoverPNG}
						autoPlay
						muted
						loop
					/>
				</div>
				<div className="flex flex-col items-center">
					<SVPageTitle className="mb-5 font-medium text-gray-300 -md:px-6 type-title">
						{t('episodesSection.title')}
					</SVPageTitle>
					<div className="text-3xl text-center text-gray-500 type-subnav -md:px-6 text-pretty">
						{t('episodesSection.description')}
					</div>
					<_EpisodesParallax />
				</div>
				<SVInfoSection movie={SVMeetYourHostVideo} title={t('meetYourHostSection.title')} description={t('meetYourHostSection.description')} descriptionExtraLine={t('meetYourHostSection.descriptionExtraLine')} label={t('meetYourHostSection.label')} CTAButton={<_ScrollToPricingButton label={t('meetYourHostSection.buttonCopy')} />} />
				<SVPageMargins>
					<div className="flex items-center w-full max-w-screen-xl mx-auto mt-6 space-x-32 -md:flex-col -md:space-y-10 -md:px-6 -md:space-x-0 -lg:space-x-10 mb-28">
						<div className="w-full flex-center -md:mb-20">
							<div className="-md:text-center -md:max-w-lg flex max-w-[320px] flex-col text-start">
								<div className="text-gray-700 type-subnav">
									{t('practicalApplicationSection.label')}
								</div>
								<SVPageTitle className="mt-5 font-medium text-gray-300 -md:text-center text-start">
									{t('practicalApplicationSection.title')}
								</SVPageTitle>
								<div className="text-3xl text-gray-500 type-subnav text-pretty">
									{t('practicalApplicationSection.description')}
								</div>
								<div className="flex justify-start w-full -md:flex-center mt-7">
									<_ScrollToPricingButton
										label={t('practicalApplicationSection.buttonCopy')}
									/>
								</div>
							</div>
						</div>
						<_Projects />
					</div>
				</SVPageMargins>
				<SVPageMargins>
					<div className="flex flex-col w-full max-w-screen-xl mx-auto -md:mt-12 mt-36">
						<SVPageTitle className="font-medium text-gray-300">
							{t('extraBonusSection.title')}
						</SVPageTitle>
						<div className="w-full text-3xl text-center text-gray-500 -md:mb-0 mb-28 text-pretty">
							{t('extraBonusSection.description')}
						</div>
						<_ExtraContentList />
					</div>
				</SVPageMargins>
				<div className="flex-col w-full mt-40 flex-center -md:mt-24">
					<div className="flex-center w-full flex-col px-[var(--page-margin)]">
						<SVPageTitle className="w-full mb-5 font-medium text-center text-gray-300">
							{t('quotesSection.title')}
						</SVPageTitle>
						<div className="w-full mb-24 text-3xl text-center text-gray-500 -md:mb-16 text-pretty">
							{t('quotesSection.description')}
						</div>
					</div>
					<SVSocialProfileGrid
						SOCIAL_PROOF_LIST={SOCIAL_PROOF_LIST}
						showMoreCopy={t('quotesSection.buttonCopy')}
					/>
				</div>
				<SVPageMargins id="pricing">
					<div className="flex flex-row items-center justify-between w-full max-w-screen-xl mx-auto mt-64 -sm:flex-col -sm:space-y-16 -md:mt-28">
						<div className="flex-col w-full flex-center">
							<div className="text-xl text-gray-600">
								{t('courseDetailsSection.label')}
							</div>
							<div
								className="text-4xl font-medium text-gray-200 -sm:text-3xl mt-7 opacity-30"
								dangerouslySetInnerHTML={{
									__html: OLD_PRICE,
								}}
							/>
							<div
								className="-md:text-9xl -sm:text-[23vw] -md:mt-4 text-[120px] font-semibold text-gray-200 "
								style={{ letterSpacing: '-0.06em' }}
							>
								{NEW_PRICE}
							</div>
							{language === Language.PT_BR && (
								<div className="text-4xl text-gray-500 -md:mt-3 -sm:text-3xl">
									{t('courseDetailsSection.newPrice')}
								</div>
							)}
							<div
								className={clsx(
									'-md:flex-col -md:space-y-4 -md:space-x-0 flex space-x-2.5',
									language === Language.PT_BR ? 'mt-11' : 'mt-5',
								)}
							>
								<_GetCourseButton
									label={t('courseDetailsSection.buttonCopy')}
									usePadding={language === Language.EN_US}
									language={language}
								/>
								{language === Language.PT_BR && (
									<SVButton
										Component={SVLink}
										to={SAVEE_URL_COURSE_CHECKOUT}
										className="font-medium"
									>
										Comprar pelo Savee
									</SVButton>
								)}
							</div>
						</div>
						<div className="w-full flex-center">
							<div className="flex flex-col items-start space-y-6 w-fit">
								{OFFER_DETAILS_LIST.map((item, index) => (
									<div
										key={index}
										className="flex items-center space-x-3 text-start"
									>
										<SVImage
											src={iconCheckItems}
											className="w-4 h-4"
											alt="check items"
										/>
										<div className="text-gray-500 type-subnav">{item}</div>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="w-full space-x-1 font-semibold text-center text-gray-500 type-subnav mt-28">
						{t('footerSection.title')}
						<a
							href="https://savee.it/contact-us/"
							className="ml-1 underline"
							target="_blank"
							rel="noreferrer"
						>
							{t('footerSection.linkCopy')}
						</a>
						{t('footerSection.textAfterLink')}
					</div>
				</SVPageMargins>
				<SVFooterImageGallery />

				<SVPageMargins>
					<div className="-sm:hidden">
						<SVLandingPageFooter className="my-10" />
					</div>
				</SVPageMargins>
			</div>
		</>
	);
};

const Page = SVWithBodyClass('theme-dark')(_Page);

// @ts-ignore
Page.withNavHidden = ({ hasCourse }) => !hasCourse;

export default Page;
